<!--
 * @Author: your name
 * @Date: 2020-12-01 13:21:03
 * @LastEditTime: 2021-05-19 14:52:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\authorizationsuccess\index.vue
-->
<template>
  <div class="all" v-wechat-title="$route.meta.title">
    <navigation :noleft="true" :msg="msg" ></navigation>
    <p class="timeclass">内容生成中，请勿关闭该页面（{{ times }} S）</p>
    <div class="progress">
      <van-progress
        :show-pivot="showPivot"
        :stroke-width="strokeWidth"
        pivot-text="橙色"
        color="#EF811A"
        :percentage="percentage"
      />
    </div>
    <p @click="tochome">到首页逛逛</p>
    <div class="adclasses">
      <img alt="广告图片" src="../../assets/cd.jpg" />
    </div>
  </div>
</template>
<script>
import base from "../../../util/base"
var intervalID;
export default {
  mounted() {
    intervalID = setInterval(() => {
      this.times--;
      this.percentage += 3.333333;
      if (this.times < 1) {
        clearInterval(intervalID);
        this.$router.push("/authorizationsuccesstwo")
      }
    }, 1000);
  },
  methods:{
    tochome(){
      window.location = base.tourlc;
    }
  },
  destroyed(){
    clearInterval(intervalID);
  },
  data() {
    return {
      msg: "",
      aa: "noleft",
      color: "red",
      showPivot: false, //是否显示进度条内容
      strokeWidth: ".37rem",
      times: 30, //剩余时间
      percentage: 0, //进度条长度
    };
  },
};
</script>
<style scoped>
.all >>> [class*=van-hairline]::after{
  border: none;
}
.p1{
  text-align: center;
  font-size: .3rem;
}
.all {
  height: 100%;
  background-color: #ffffff;
}
.timeclass {
  text-align: center;
  font-size: 0.3rem;
  color: #222222;
  font-weight: 500;
}
.progress {
  margin-top: 0.17rem;
  margin: 0.3rem;
  border-radius: 0.6rem;
  overflow: hidden;
}
.progress + p {
  text-align: center;
  font-size: 0.3rem;
  color: #409EFF;
}
.adclasses {
  text-align: center;
  margin-top: 0.5rem;
}
.adclasses img {
  width: 6.9rem;
  height: 10.42rem;
  border-radius: 10px;
}
</style>